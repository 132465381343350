import React from 'react';

import { Modal } from '../index';

import css from './SaveSearchModal.module.scss';

const SaveSearchDesktopModal = (props) => {
  const {
    isOpen,
    children,
    onManageDisableScrolling,
    onClose,
  } = props;

  return (
    <Modal
      className={css.desktopModal}
      id='saveSearchDesktopModal'
      closeButtonMessage=' '
      isOpen={isOpen}
      onManageDisableScrolling={onManageDisableScrolling}
      onClose={onClose}
    >
      <div>
        {children}
      </div>
    </Modal>
  );
};

export default SaveSearchDesktopModal;
