import React from 'react';
import debounce from 'lodash/debounce';

import {
  FILTER_ENGINE_HOURS_MIN,
  FILTER_ENGINE_HOURS_MAX,
  FILTER_ENGINE_HOURS_STEP,
  FILTER_KEYS_ENGINE_HOURS,
  nullEngineHoursValues,
} from '../../SearchPage.constants';
import { FormattedMessage } from '../../../../util/reactIntl';
import { DEBOUNCE_WAIT_TIME, fixRangeFilters } from '../../SearchPage.helpers';

import RangeFormFields from '../RangeFormFields/RangeFormFields';
import FilterPopup from '../FilterPopup/FilterPopup';
import FilterPlain from '../FilterPlain/FilterPlain';

import css from './EngineHoursFilter.module.css';

const EngineHoursFilter = (props) => {
  const {
    intl,
    showAsPopup,
    searchValues,
    onSubmit,
    onChangeMobileFilters,
    mobileSearchParams,
    isMobile,
  } = props;

  const mobileOrDesktopValues = isMobile ? mobileSearchParams : searchValues;

  const defaultValues = FILTER_KEYS_ENGINE_HOURS.
    reduce((acc, curr) => ({ ...acc, [curr]: mobileOrDesktopValues[curr] }), { });
  const [stateValues, setValues] = React.useState(nullEngineHoursValues);
  const isSelected = FILTER_KEYS_ENGINE_HOURS
    .map(key => isMobile ? mobileSearchParams?.[key] : searchValues[key])
    .some(i => !!i);

  React.useEffect(() => {
    setValues(defaultValues);
  }, [...Object.values(defaultValues)]);

  const handleSubmit = () => {
    onSubmit(stateValues);
  };

  const handleClear = () => {
    isMobile ? onChangeMobileFilters(nullEngineHoursValues) : onSubmit(nullEngineHoursValues);
    // onChangeMobileFilters(nullEngineHoursValues);
    // onSubmit(nullEngineHoursValues);
  };

  const handleCancel = () => {
  };

  const handleChange = debounce(
    values => {
      const vals = {
        ...stateValues,
        ...values,
      };
      setValues(vals);
      if (!showAsPopup) {
        values = fixRangeFilters(values);
        onChangeMobileFilters(values);
        // onSubmit(vals);
      }
    },
    DEBOUNCE_WAIT_TIME,
    { leading: false, trailing: true }
  );

  const label = intl.formatMessage({
    id: 'AircraftSearchPage.primaryFilters.engineHoursFilter.title',
  });

  const rangeLabel = number => {
    return (
      <FormattedMessage
        id='AircraftSearchPage.primaryFilters.engineHoursFilter.engineTitle'
        values={{ number }}
      />
    );
  };

  const content = FILTER_KEYS_ENGINE_HOURS.map((key, index) => {
    return (
      <RangeFormFields
        key={key}
        fieldKey={key}
        rangeLabel={rangeLabel(index + 1)}
        min={FILTER_ENGINE_HOURS_MIN}
        max={FILTER_ENGINE_HOURS_MAX}
        step={FILTER_ENGINE_HOURS_STEP}
        initialValue={mobileOrDesktopValues[key]}
        onChange={handleChange}
      />
    );
  });

  if (showAsPopup) {
    return (
      <FilterPopup
        id={'engine_filter_popup'}
        intl={intl}
        label={label}
        showAsPopup={showAsPopup}
        isSelected={isSelected}
        onClear={handleClear}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      >
        {content}
      </FilterPopup>
    );
  } else {
    return (
      <FilterPlain
        id={'engine_filter_plain'}
        label={label}
        isSelected={isSelected}
        onClear={handleClear}
        onSubmit={() => {}}
      >
        {content}
      </FilterPlain>
    )
  }
};

export default EngineHoursFilter;
