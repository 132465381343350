import React from 'react';
import debounce from 'lodash/debounce';

import {
  fixRangeFilters,
  DEBOUNCE_WAIT_TIME,
} from '../../SearchPage.helpers';

import FilterPopup from '../FilterPopup/FilterPopup';
import FilterPlain from '../FilterPlain/FilterPlain';
import RangeFormFields from '../RangeFormFields/RangeFormFields';

import css from './RangeFilter.module.css';

const RangeFilter = (props) => {
  const {
    intl,
    label,
    showAsPopup,
    liveEdit,
    searchValues,
    rangeLabel,
    queryParamNames,
    min,
    max,
    step,
    isFormatted,
    onSubmit,
    onSetSearchValues,
    onChangeMobileFilters,
    isMobile,
    mobileSearchParams,
  } = props;

  const queryParamName = queryParamNames[0];
  const nullValue = { [queryParamName]: null };
  const value = isMobile
    ? mobileSearchParams[queryParamName]
    : searchValues[queryParamName]
  ;
  const [stateValue, setValue] = React.useState(nullValue);
  const [prevValue, setPrevValue] = React.useState(value);
  const isSelected = !!value;

  React.useEffect(() => {
    setValue({ [queryParamName]: value });
    if (!value) {
      setPrevValue(null);
    }
  }, [value]);

  const handleChange = debounce(
    value => {
      onSetSearchValues(value);
      setValue(value);
      if (liveEdit) {
        value = fixRangeFilters(value);
        onChangeMobileFilters(value);
        // onSubmit(value);
      }
    },
    DEBOUNCE_WAIT_TIME,
    { leading: false, trailing: true }
  );

  const handleSubmit = () => {
    onSubmit(stateValue);
    setPrevValue(stateValue[queryParamName]);
  };

  const handleClear = () => {
    if (isMobile) {
      onChangeMobileFilters(nullValue);
      return ;
    }
    onSubmit(nullValue);
    setPrevValue(stateValue[queryParamName]);
  };

  const handleCancel = () => {
    if (prevValue !== value) {
      onSubmit({ [queryParamName]: prevValue });
    }
  };

  const handlePlainClear = () => {
    if (isMobile) {
      onChangeMobileFilters(nullValue);
      return ;
    }
    onSetSearchValues(nullValue);
    // if (liveEdit) {
    //   onSubmit(nullValue);
    // }
  };

  const content = (
    <RangeFormFields
      fieldKey={queryParamName}
      rangeLabel={rangeLabel}
      min={min}
      max={max}
      step={step}
      initialValue={value}
      isFormatted={isFormatted}
      onChange={handleChange}
    />
  );

  if (showAsPopup) {
    return (
      <FilterPopup
        id={'range_filter_popup'}
        intl={intl}
        label={label}
        showAsPopup={showAsPopup}
        isSelected={isSelected}
        onClear={handleClear}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      >
        {content}
      </FilterPopup>
    );
  } else {
    return (
      <FilterPlain
        id={'range_filter_plain'}
        label={label}
        isSelected={isSelected}
        onClear={handlePlainClear}
        onSubmit={() => {}}
      >
        {content}
      </FilterPlain>
    )
  }
};

export default RangeFilter;
