import React from 'react';
import { bool, func, number, object, string, node } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import numeral from 'numeral';

import { FormattedMessage, injectIntl, intlShape } from '../../../../util/reactIntl';
import { DEBOUNCE_WAIT_TIME } from '../../SearchPage.helpers';

import { Form } from '../../../../components';
import {
  MaskedCurrencyInputAdapter,
} from '../../../../components/FieldMaskedCurrencyInput/FieldMaskedCurrencyInput';

import css from './AircraftPriceFilterForm.module.scss';

// PriceFilterForm component
const PriceFilterFormComponent = props => {
  const { liveEdit, onChange, onSubmit, onCancel, onClear, min, max, ...rest } = props;

  if (liveEdit && !onChange) {
    throw new Error('PriceFilterForm: if liveEdit is true you need to provide onChange function');
  }

  if (!liveEdit && !(onCancel && onClear && onSubmit)) {
    throw new Error(
      'PriceFilterForm: if liveEdit is false you need to provide onCancel, onClear, and onSubmit functions'
    );
  }

  const handleChange = debounce(
    formState => {
      if (formState.dirty && formState.valid) {
        const { minPrice, maxPrice, ...restValues } = formState.values;
        onChange({
          minPrice,
          maxPrice,
          ...restValues,
        });
      }
    },
    DEBOUNCE_WAIT_TIME,
    { leading: false, trailing: true }
  );

  const handleSubmit = values => {
    const { minPrice, maxPrice, ...restValues } = values;
    return onSubmit({
      minPrice,
      maxPrice,
      ...restValues,
    });
  };

  const formCallbacks = liveEdit
    ? { onSubmit: () => null }
    : { onSubmit: handleSubmit, onCancel, onClear };
  return (
    <FinalForm
      {...rest}
      {...formCallbacks}
      render={formRenderProps => {
        const {
          form,
          handleSubmit,
          id,
          showAsPopup,
          onClear,
          onCancel,
          isOpen,
          isInSideBar,
          contentRef,
          style,
          intl,
          min,
          max,
          step,
          children,
          rangeLabel,
          values,
        } = formRenderProps;
        const handleCancel = () => {
          // reset the final form to initialValues
          form.reset();
          onCancel();
        };

        const clear = intl.formatMessage({ id: 'PriceFilterForm.clear' });
        const cancel = intl.formatMessage({ id: 'PriceFilterForm.cancel' });
        const submit = intl.formatMessage({ id: 'PriceFilterForm.submit' });

        const classes = classNames(css.root, {
          [css.popup]: showAsPopup,
          [css.isOpenAsPopup]: showAsPopup && isOpen,
          [css.plain]: !showAsPopup,
          [css.isOpen]: !showAsPopup && isOpen,
        });

        const maxPriceFieldState = form.getFieldState('maxPrice');
        const maxPriceFieldIsActive = maxPriceFieldState && maxPriceFieldState.active;
        const minPriceFieldState = form.getFieldState('minPrice');
        const minPriceFieldIsActive = minPriceFieldState && minPriceFieldState.active;

        const maxValidate = (value, values) => {
          if (value === '') {
            return 'Invalid values';
          }
          const maxValue = Number(value);
          const minValue = Number(values.minPrice);
          return minValue <= maxValue ? undefined : 'Invalid values';
        };

        const minValidate = (value, values) => {
          if (value === '') {
            return 'Invalid values';
          }
          const minValue = Number(value);
          const maxValue = Number(values.maxPrice);
          return minValue <= maxValue ? undefined : 'Invalid values';
        };

        const parse = value => value && numeral(value).value();

        return (
          <Form
            className={classes}
            onSubmit={handleSubmit}
            tabIndex="0"
            contentRef={contentRef}
            style={{ minWidth: '280px', ...style }}
          >
            <div className={css.contentWrapper}>
              {showAsPopup || rangeLabel ? (
                <span className={css.label}>
                  {rangeLabel ? rangeLabel : <FormattedMessage id='PriceFilterForm.label' />}
                </span>
              ) : null}
              <div className={css.inputsWrapper}>
                <Field
                  className={classNames(css.minPrice, { [css.priceInSidebar]: isInSideBar })}
                  id={`${id}.minPrice`}
                  name='minPrice'
                  component={MaskedCurrencyInputAdapter}
                  placeholder='From'
                  parse={parse}
                  validate={minValidate}
                  validateFields={['maxPrice']}
                />
                <span className={css.priceSeparator}>-</span>
                <Field
                  className={classNames(css.maxPrice, { [css.priceInSidebar]: isInSideBar })}
                  id={`${id}.maxPrice`}
                  name='maxPrice'
                  component={MaskedCurrencyInputAdapter}
                  placeholder='To'
                  parse={parse}
                  validate={maxValidate}
                  validateFields={['minPrice']}
                />
              </div>
              {!form.getState().valid && <div className={css.error}>Incorrect values</div>}
            </div>

            <div className={css.sliderWrapper} />

            {liveEdit ? (
              <FormSpy
                onChange={handleChange}
                subscription={{
                  values: true,
                  dirty: true,
                  valid: true,
                }}
              />
            ) : (
              <div className={css.buttonsWrapper}>
                <button className={css.clearButton} type="button" onClick={onClear}>
                  {clear}
                </button>
                <button className={css.cancelButton} type="button" onClick={handleCancel}>
                  {cancel}
                </button>
                <button className={css.submitButton} type="submit">
                  {submit}
                </button>
              </div>
            )}
            {children}
          </Form>
        );
      }}
    />
  );
};

PriceFilterFormComponent.defaultProps = {
  rangeLabel: null,
  liveEdit: false,
  showAsPopup: false,
  isOpen: false,
  contentRef: null,
  style: null,
  min: 0,
  step: 1,
  onCancel: null,
  onChange: null,
  onClear: null,
  onSubmit: null,
};

PriceFilterFormComponent.propTypes = {
  rangeLabel: node,
  id: string.isRequired,
  liveEdit: bool,
  showAsPopup: bool,
  onCancel: func,
  onChange: func,
  onClear: func,
  onSubmit: func,
  isOpen: bool,
  contentRef: func,
  style: object,
  min: number.isRequired,
  max: number.isRequired,
  step: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

const AircraftPriceFilterForm = injectIntl(PriceFilterFormComponent);

export default AircraftPriceFilterForm;
