export const selectStyles = (
  isMulti,
  showMultiValues,
  showSearchIcon,
  valid,
  styles,
  ) => {
  return {
    control: (base, state) => ({
      height: isMulti ? 'auto' : '41px',
      display: 'flex',
      alignItems: 'center',
      padding: isMulti ? '8px 0' : '11px 12px',
    }),
    input: () => ({
      display: 'flex',
      alignItems: 'center',
      position: isMulti ? 'relative' : 'absolute',
    }),

    valueContainer: () => ({
      paddingLeft: '0px',
      position: 'relative',
      zIndex: 1,
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
    }),
    multiValue: () => ({
      alignItems: 'center',
      justifyContent: 'stretch',
      display: showMultiValues ? 'flex' : 'none',
      lineHeight: '20px',
      height: '36px',
      background: '#EFCA80',
      color: '#454545',
      fontSize: '16px',
      fontWeight: '700',
      padding: '0 12px',
      margin: '8px 8px 8px 0',
    }),
    indicatorsContainer: () => ({
      position: 'relative',
      zIndex: 2,
      left: '10px',
      top: '3px',
      cursor: 'pointer',
      height: '36px',
    }),
    option: () => ({
      padding: '10px',

      '&:hover': {
        borderColor: 'red',
      },
    }),
    indicatorSeparator: () => ({
      display: isMulti ? 'none' : 'block',
    }),
    placeholder: () => ({
      color: '#b2b2b2',
      position: 'absolute',
      transform: 'translateY(-50%)',
      top: '50%',
    }),
    menu: () => ({
      marginTop: '0',
    }),
    menuList: () => ({
      boxShadow: '-2px 7px 21px rgba(0, 0, 0, 0.1)',
      fontWeight: 400,
      maxHeight: '250px',
      overflow: 'auto',
      // position: 'absolute',
      width: '100%',
      background: 'white',
      zIndex: 1000000,
      left: '0',
      padding: '0 12px',
    }),
    container: () => ({
      width: '100%',
      border: valid ? '1px solid #2E9C2C' : '1px solid #242424',
      padding: showSearchIcon ? '0 17px 0 44px' : '0 0 0 0',
      margin: '0!important',
    }),
    ...styles,
  };
};
