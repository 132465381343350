import React from 'react';

import { GreatGoodDealsService } from '../../../../services/GreatGoodDeals.service';

import SelectMultipleFilter from '../../SelectMultipleFilter/SelectMultipleFilter';
import GreatDealIcon from '../../../../components/PricingAnalysisIcons/GreatDealIcon';
import GoodDealIcon from '../../../../components/PricingAnalysisIcons/GoodDealIcon';
import FairDealIcon from '../../../../components/PricingAnalysisIcons/FairDealIcon';
import HighPriceIcon from '../../../../components/PricingAnalysisIcons/HighPriceIcon';
import OverpricedIcon from '../../../../components/PricingAnalysisIcons/OverpricedIcon';
import NoPriceAnalysisIcon from '../../../../components/PricingAnalysisIcons/NoPriceAnalysisIcon';

import css from './DealRating.module.css';

const getIcon = key => {
  switch (key) {
    case GreatGoodDealsService.GREAT_DEAL:
      return <GreatDealIcon />;
    case GreatGoodDealsService.GOOD_DEAL:
      return <GoodDealIcon />;
    case GreatGoodDealsService.FAIR_DEAL:
      return <FairDealIcon />;
    case GreatGoodDealsService.HIGH_DEAL:
      return <HighPriceIcon />;
    case GreatGoodDealsService.OVERPRICED_DEAL:
      return <OverpricedIcon />;
    case GreatGoodDealsService.NO_EVALUATION_AVAILABLE_DEAL:
      return <NoPriceAnalysisIcon />;
    default:
      return null;
  }
};

const DealRatingFilter = (props) => {
  const {
    dealCounts,
    options,
    showAsPopup,
    isMobile,
    onSubmit,
    onChangeMobileFilters,
  } = props;

  const optionsWithIcons = options.map(item => {
    const icon = getIcon(item.key);
    const count = dealCounts[item.key];
    return {
      ...item,
      label: <><span className={css.icon}>{icon}</span>{`${item.label} (${count})`}</>,
    };
  });

  const handleSubmit = params => {
    if (showAsPopup || !isMobile) {
      onSubmit(params)
    } else {
      onChangeMobileFilters(params);
    }
  };

  const handleChangeMobileFilters = params => {
    onChangeMobileFilters(params);
  };

  return (
    <SelectMultipleFilter
      {...props}
      options={optionsWithIcons}
      onSubmit={handleSubmit}
      onChangeMobileFilters={handleChangeMobileFilters}
    />
  );
};

export default DealRatingFilter;
