import React from 'react';
import { Field } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import xor from 'lodash/xor';

import {
  FILTER_KEY_HOME_TYPE,
} from '../../SearchPage.constants';

import FilterPopup from '../../FilterPopup/FilterPopup';
import FilterPlain from '../../FilterPlain/FilterPlain';
import SimpleIconCheckbox from '../../../../components/FieldCheckbox/SimpleIconCheckbox';

import css from './HomeTypeFilter.module.scss';

const TypesAdapter = props => {
  const {
    input,
    isMobile,
    options,
    intl,
    categories,
    onChange,
    ...rest
  } = props;

  const categoryLabel = intl.formatMessage({
    id: 'AircraftSearchPage.primaryFilters.categoryFilter.label',
  });

  const handleClick = (checked, key) => {
    onChange(checked, key);
    input.onChange(xor(categories, [key]));
  };

  return (
    <div className={css.categories}>
      {!isMobile && <label className={css.label}>{categoryLabel}</label>}
      {options.map(option => {
        const isOptionChecked = categories.includes(option.key);
        return (
          <SimpleIconCheckbox
            key={option.key}
            textClassName={css.item}
            id={option.key}
            value={option.key}
            label={option.label}
            checked={isOptionChecked}
            onClick={handleClick}
            {...rest}
          />
        );
      })}
    </div>
  );
};

const HomeTypeFilter = (props) => {
  const {
    options,
    isMobile,
    label,
    intl,
    showAsPopup,
    searchValues,
    onSubmit,
    onChangeMobileFilters,
  } = props;

  const initialCategories = searchValues[FILTER_KEY_HOME_TYPE] || [];
  const [categories, setCategories] = React.useState(initialCategories);
  const hasInitialValues = !!initialCategories.length;

  React.useEffect(() => {
    if (initialCategories && !initialCategories.length && categories.length) {
      handleClear();
    }
  }, [searchValues]);

  const handleSubmit = values => {
    const homeType = Array.isArray(values)
      ? values
      : values && values.homeType
    ;
    onSubmit({
      [FILTER_KEY_HOME_TYPE]: homeType && homeType.length ? homeType : null,
    });
  };

  const handleCancel = () => {
    if (!isEqual(initialCategories, categories)) {
      setCategories(initialCategories);
    }
  };

  const handleClear = () => {
    setCategories([]);
    if (!showAsPopup) {
      onChangeMobileFilters({
        [FILTER_KEY_HOME_TYPE]: null,
      });
    }
  };

  const handleChange = (checked, key) => {
    const newCategories = xor(categories, [key]);
    setCategories(newCategories);
    if (!showAsPopup) {
      onChangeMobileFilters({
        [FILTER_KEY_HOME_TYPE]: newCategories.length ? newCategories.join(',') : null,
      });
    }
  };

  const content = (
    <Field
      name='homeType'
      id='homeType'
      intl={intl}
      categories={categories}
      isMobile={isMobile}
      options={options}
      initialValue={{
        homeType: initialCategories,
      }}
      onChange={handleChange}
      component={TypesAdapter}
    />
  );

  if (showAsPopup) {
    return (
      <FilterPopup
        id={'homeType_filter_popup'}
        intl={intl}
        label={label}
        showAsPopup={showAsPopup}
        isSelected={hasInitialValues}
        initialValues={initialCategories}
        onClear={handleClear}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      >
        {content}
      </FilterPopup>
    );
  } else {
    return (
      <FilterPlain
        id={'homeType_filter_popup'}
        label={label}
        isSelected={hasInitialValues}
        onClear={handleClear}
        onSubmit={() => {}}
      >
        {content}
      </FilterPlain>
    )
  }
};

export default HomeTypeFilter;
