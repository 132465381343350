import React from 'react';

import SaveSearchDesktopModal from './SaveSearchDesktopModal';
import SaveSearchMobileModal from './SaveSearchMobileModal';
import SaveSearchForm from './SaveSearchForm';

import css from './SaveSearchModal.module.scss';

const SaveSearchModal = (props) => {
  const {
    editItem,
    currentUserInProgress,
    isMobileLayout,
    isOpen,
    showAsModalMaxWidth,
    intl,
    onManageDisableScrolling,
    saveSavedSearchState,
    onClose,
    onSubmit,
  } = props;

  const initialValues = editItem
    ? { name: editItem.name, freq: editItem.freq }
    : {}
  ;

  const content = (
    <>
      <div className={css.title}>
        EDIT SEARCH
      </div>
      {isOpen && (
        <SaveSearchForm
          initialValues={initialValues}
          currentUserInProgress={currentUserInProgress}
          intl={intl}
          isModalOpen={isOpen}
          saveSearchField={saveSavedSearchState}
          onSubmit={onSubmit}
        />
      )}
    </>
  );

  if (isMobileLayout && isOpen) {
    return (
      <SaveSearchMobileModal
        isOpen={isOpen}
        showAsModalMaxWidth={showAsModalMaxWidth}
        onManageDisableScrolling={onManageDisableScrolling}
        onClose={onClose}
      >
        {content}
      </SaveSearchMobileModal>
    );
  }

  return (
    <SaveSearchDesktopModal
      isOpen={isOpen}
      onManageDisableScrolling={onManageDisableScrolling}
      onClose={onClose}
    >
      {content}
    </SaveSearchDesktopModal>
  );
};

export default SaveSearchModal;
