import React from 'react';
import xorBy from 'lodash/xorBy';
import uniqBy from 'lodash/uniqBy';
import remove from 'lodash/remove';

import SimpleIconCheckbox from '../../../../components/FieldCheckbox/SimpleIconCheckbox';

import css from './CategoryFilter.module.css';

const isItemChecked = (categories, item) => {
  return !!categories.find(i => i.value === item.value);
};

const CategoryGroup = (props) => {
  const {
    group,
    categories,
    onChange,
  } = props;

  const isGroupItemChecked = group.items.every(i => categories.find(ii => ii.value === i.value));

  const handleGroupItemClick = checked => {
    let newSelectedCategories = categories;
    if (checked) {
      newSelectedCategories = newSelectedCategories.concat(group.items);
    } else {
      remove(newSelectedCategories, i => group.items.find(ii => ii.value === i.value));
    }
    onChange(uniqBy(newSelectedCategories, 'value'));
  };

  const handleItemClick = (checked, value) => {
    const newSelectedCategories = xorBy(categories, [value], 'value');
    onChange(newSelectedCategories);
  };

  return (
    <div className={css.group}>
      <SimpleIconCheckbox
        textClassName={css.groupLabel}
        id={group.label}
        label={group.label}
        checked={isGroupItemChecked}
        onClick={handleGroupItemClick}
      />

      <div className={css.groupItems}>
        {group.items.map(item => (
          <SimpleIconCheckbox
            key={item.label}
            id={item.label}
            label={item.label}
            value={item}
            checked={isItemChecked(categories, item)}
            onClick={handleItemClick}
          />
        ))}
      </div>
    </div>
  );
};

export default CategoryGroup;
