import React from 'react';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

import { FILTER_KEY_CATEGORY } from '../../SearchPage.constants';

import FilterPopup from '../FilterPopup/FilterPopup';
import FilterPlain from '../FilterPlain/FilterPlain';
import CategoryGroup from './CategoryGroup';

import css from './CategoryFilter.module.css';

const CategoryFilter = (props) => {
  const {
    isMobile,
    airplanesCategoriesField,
    label,
    intl,
    showAsPopup,
    searchValues,
    onSubmit,
    onChangeMobileFilters,
  } = props;

  const [categories, setCategories] = React.useState([]);
  const stateCategories = searchValues[FILTER_KEY_CATEGORY] || [];
  const hasInitialValues = !!stateCategories.length;

  React.useEffect(() => {
    if (stateCategories) {
      setCategories(cloneDeep(stateCategories));
    }
  }, [searchValues]);

  const handleSubmit = () => {
    onSubmit({
      [FILTER_KEY_CATEGORY]: categories.length ? categories : null,
    });
  };

  const handleCancel = () => {
    if (!isEqual(stateCategories, categories)) {
      setCategories(stateCategories);
    }
  };

  const handleClear = () => {
    setCategories([]);
    onSubmit({ [FILTER_KEY_CATEGORY]: null });
    onChangeMobileFilters({ [FILTER_KEY_CATEGORY]: null });
  };

  const handleCategoryChange = values => {
    setCategories(values);
    if (!showAsPopup) {
      onChangeMobileFilters({
        [FILTER_KEY_CATEGORY]: values.length ? values.map(i => i.value).join(',') : null,
      });
      // onSubmit({
      //   [FILTER_KEY_CATEGORY]: values.length ? values : null,
      // });
    }
  };

  const categoryLabel = intl.formatMessage({
    id: 'AircraftSearchPage.primaryFilters.categoryFilter.label',
  });

  const content = (
    <div className={css.categories}>
      {!isMobile && <label className={css.label}>{categoryLabel}</label>}
      {airplanesCategoriesField.value.map(group => (
        <CategoryGroup
          key={group.label}
          group={group}
          categories={categories}
          onChange={handleCategoryChange}
        />
      ))}
    </div>
  );

  if (showAsPopup) {
    return (
      <FilterPopup
        id={'category_filter_popup'}
        intl={intl}
        label={label}
        showAsPopup={showAsPopup}
        isSelected={hasInitialValues}
        onClear={handleClear}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      >
        {content}
      </FilterPopup>
    );
  } else {
    return (
      <FilterPlain
        id={'category_filter_plain'}
        label={label}
        isSelected={hasInitialValues}
        onClear={handleClear}
        onSubmit={() => {}}
      >
        {content}
      </FilterPlain>
    )
  }
};

export default CategoryFilter;
