import React from 'react';

import { ModalInMobile } from '../index';

const SaveSearchMobileModal = (props) => {
  const {
    isOpen,
    children,
    showAsModalMaxWidth,
    onManageDisableScrolling,
    onClose,
  } = props;

  return (
    <ModalInMobile
      id='saveSearchMobileModal'
      isModalOpenOnMobile={isOpen}
      showAsModalMaxWidth={showAsModalMaxWidth}
      onManageDisableScrolling={onManageDisableScrolling}
      onClose={onClose}
    >
      {children}
    </ModalInMobile>
  );
};

export default SaveSearchMobileModal;
