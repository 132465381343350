import React from 'react';
import classNames from 'classnames';
import numeral from 'numeral';
import isNil from 'lodash/isNil';

import { parseRange } from '../../SearchPage.helpers';
import { onlyNumberKeyDown } from '../../../../util/validators';
import usePrevious from '../../../../hooks/usePrevious';

import { RangeSlider } from '../../../../components';

import css from './RangeFormFields.module.css';

const RangeFormFields = (props) => {
  const {
    rangeLabel,
    fieldKey,
    min,
    max,
    step,
    initialValue,
    isFormatted,
    onChange,
  } = props;

  const [stateMinValue, setMinValue] = React.useState(null);
  const [stateMaxValue, setMaxValue] = React.useState(null);

  const parsedInitialValue = parseRange(initialValue);

  const prevInitialValue = usePrevious(initialValue);
  React.useEffect(() => {
    if (prevInitialValue && isNil(initialValue)) {
      setMinValue(min);
      setMaxValue(max);
    }
  }, [initialValue]);

  const minValue = stateMinValue !== null
    ? stateMinValue
    : parsedInitialValue
      ? parsedInitialValue.minPrice
      : min
  ;
  const maxValue = stateMaxValue !== null
    ? stateMaxValue
    : parsedInitialValue
      ? parsedInitialValue.maxPrice
      : max
  ;

  const rangeMinValue = Number(minValue) < min || Number(minValue) > Number(maxValue)
    ? min
    : Number(minValue) > max
      ? max
      : Number(minValue)
  ;
  const rangeMaxValue = Number(maxValue) > max || Number(maxValue) < Number(minValue)
    ? max
    : Number(maxValue) < min
      ? min
      : Number(maxValue)
  ;

  const getRangeError = (minValue, maxValue) => {
    return Number(minValue) > Number(maxValue)
      || Number(minValue) < min || Number(minValue) > max
      || Number(maxValue) < min || Number(maxValue) > max
    ;
  };

  const handleMinChange = e => {
    const value = e.target.value;
    const parsedValue = !isFormatted
      ? Number(value)
      : !!value
        ? numeral(value).value()
        : 0
    ;
    setMinValue(parsedValue);
    handleChange(parsedValue, Number(maxValue));
  };

  const handleMaxChange = e => {
    const value = e.target.value;
    const parsedValue = !isFormatted
      ? Number(value)
      : !!value
        ? numeral(value).value()
        : 0
    ;
    setMaxValue(parsedValue);
    handleChange(Number(minValue), parsedValue);
  };

  const handleChange = (minVal, maxVal) => {
    if (getRangeError(minVal, maxVal)) {
      return ;
    }
    let strValue;
    if (minVal === min && maxVal === max) {
      strValue = null;
    } else {
      strValue = `${minVal},${maxVal}`;
    }
    onChange({ [fieldKey]: strValue });
  };

  return (
    <>
      <div className={css.contentWrapper}>
        <span className={css.label}>
          {rangeLabel}
        </span>
        <div className={css.inputsWrapper}>
          <input
            className={classNames(css.minValue)}
            value={!isFormatted ? minValue.toString() : numeral(minValue).format('0,0')}
            placeholder='From'
            onChange={handleMinChange}
            onKeyDown={onlyNumberKeyDown}
          />

          <span className={css.valueSeparator}>-</span>

          <input
            className={classNames(css.maxValue)}
            value={!isFormatted ? maxValue.toString() : numeral(maxValue).format('0,0')}
            placeholder='To'
            onChange={handleMaxChange}
            onKeyDown={onlyNumberKeyDown}
          />
        </div>
        {getRangeError(minValue, maxValue) && (
          <p>Invalid values</p>
        )}
      </div>

      <div
        className={css.sliderWrapper}
      >
        <RangeSlider
          min={min}
          max={max}
          step={step}
          handles={[rangeMinValue, rangeMaxValue]}
          onChange={handles => {
            setMinValue(handles[0]);
            setMaxValue(handles[1]);
            handleChange(handles[0], handles[1]);
          }}
        />
      </div>
    </>
  );
};

RangeFormFields.defaultProps = {
  isFormatted: true,
};

export default RangeFormFields;
