import React from 'react';

import SelectSingleFilter from './SelectSingleFilter/SelectSingleFilter';
import SelectMultipleFilter from './SelectMultipleFilter/SelectMultipleFilter';
import BookingDateRangeFilter from './BookingDateRangeFilter/BookingDateRangeFilter';
import KeywordFilter from './KeywordFilter/KeywordFilter';
import RadioGroupFilter from './RadioGroupFilter/RadioGroupFilter';
import PriceFilter from './PriceFilter/PriceFilter';
import MakeModelFilter from './AircraftSearchPage/MakeModelFilter/MakeModelFilter';
import EngineHoursFilter from './AircraftSearchPage/EngineHoursFilter/EngineHoursFilter';
import CategoryFilter from './AircraftSearchPage/CategoryFilter/CategoryFilter';
import RangeFilter from './AircraftSearchPage/RangeFilter/RangeFilter';
import AircraftPriceFilter from './AircraftSearchPage/AircraftPriceFilter/AircraftPriceFilter';
import DealRatingFilter from './AircraftSearchPage/DealRatingFilter/DealRatingFilter';
import HomeTypeFilter from './RealEstateSearchPage/HomeTypeFilter/HomeTypeFilter';

/**
 * FilterComponent is used to map configured filter types
 * to actual filter components
 */
const FilterComponent = props => {
  const {
    dealCounts,
    isMobile,
    intl,
    idPrefix,
    filterConfig,
    urlQueryParams,
    initialValues,
    searchValues,
    airplanesCategoriesField,
    getHandleChangedValueFn,
    onSetSearchValues,
    onMakeModelSearchSubmit,
    onEngineHoursSearchSubmit,
    onCategorySearchSubmit,
    onRangeFieldSubmit,
    onChangeMobileFilters,
    onHomeTypeSearchSubmit,
    ...rest
  } = props;

  const { id, type, queryParamNames, label, config, active, isTwoColums } = filterConfig;
  const { liveEdit, showAsPopup } = rest;

  if (!active) {
    return null;
  }

  const useHistoryPush = liveEdit || showAsPopup;
  const prefix = idPrefix || 'SearchPage';
  const componentId = `${prefix}.${id.toLowerCase()}`;
  const name = id.replace(/\s+/g, '-').toLowerCase();

  switch (type) {
    case 'CategoryFilter': {
      return (
        <CategoryFilter
          isMobile={isMobile}
          intl={intl}
          label={label}
          searchValues={searchValues}
          showAsPopup={showAsPopup}
          airplanesCategoriesField={airplanesCategoriesField}
          onSubmit={onCategorySearchSubmit}
          onChangeMobileFilters={onChangeMobileFilters}
        />
      );
    }
    case 'HomeTypeFilter':
      return (
        <HomeTypeFilter
          showAsPopup={showAsPopup}
          isMobile={isMobile}
          intl={intl}
          label={label}
          searchValues={searchValues}
          onSubmit={onHomeTypeSearchSubmit}
          onChangeMobileFilters={onChangeMobileFilters}
          {...config}
        />
      );
    case 'EngineHoursFilter': {
      return (
        <EngineHoursFilter
          searchValues={searchValues}
          intl={intl}
          showAsPopup={showAsPopup}
          onSubmit={onEngineHoursSearchSubmit}
          onChangeMobileFilters={onChangeMobileFilters}
          isMobile={isMobile}
          {...rest}
        />
      );
    }
    case 'MakeModalFilter': {
      return (
        <MakeModelFilter
          intl={intl}
          label={label}
          searchValues={searchValues}
          showAsPopup={showAsPopup}
          onSubmit={onMakeModelSearchSubmit}
          onChangeMobileFilters={onChangeMobileFilters}
        />
      );
    }
    case 'RangeFilter': {
      return (
        <RangeFilter
          intl={intl}
          label={label}
          showAsPopup={showAsPopup}
          liveEdit={liveEdit}
          searchValues={searchValues}
          queryParamNames={queryParamNames}
          onSubmit={onRangeFieldSubmit}
          onSetSearchValues={onSetSearchValues}
          onChangeMobileFilters={onChangeMobileFilters}
          isMobile={isMobile}
          {...config}
          {...rest}
        />
      );
    }
    case 'RadioGroupFilter': {
      return (
        <RadioGroupFilter
          id={componentId}
          label={label}
          isTwoColums={isTwoColums}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          onChangeMobileFilters={onChangeMobileFilters}
          isMobile={isMobile}
          {...config}
          {...rest}
        />
      );
    }
    case 'SelectSingleFilter': {
      return (
        <SelectSingleFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSelect={getHandleChangedValueFn(useHistoryPush)}
          onChangeMobileFilters={onChangeMobileFilters}
          {...config}
          {...rest}
        />
      );
    }
    case 'SelectMultipleFilter': {
      return (
        <SelectMultipleFilter
          id={componentId}
          label={label}
          isTwoColums={isTwoColums}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          onChangeMobileFilters={onChangeMobileFilters}
          isMobile={isMobile}
          {...config}
          {...rest}
        />
      );
    }
    case 'DealRatingFilter': {
      return (
        <DealRatingFilter
          id={componentId}
          dealCounts={dealCounts}
          label={label}
          isTwoColums={isTwoColums}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          onChangeMobileFilters={onChangeMobileFilters}
          isMobile={isMobile}
          {...config}
          {...rest}
        />
      );
    }
    case 'BookingDateRangeFilter': {
      return (
        <BookingDateRangeFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          onChangeMobileFilters={onChangeMobileFilters}
          {...config}
          {...rest}
        />
      );
    }
    case 'AircraftPriceFilter': {
      return (
        <AircraftPriceFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          onChangeMobileFilters={onChangeMobileFilters}
          {...config}
          {...rest}
        />
      );
    }
    case 'PriceFilter': {
      return (
        <PriceFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          onChangeMobileFilters={onChangeMobileFilters}
          {...config}
          {...rest}
        />
      );
    }
    case 'KeywordFilter':
      return (
        <KeywordFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          onChangeMobileFilters={onChangeMobileFilters}
          {...config}
          {...rest}
        />
      );
    default:
      return null;
  }
};

export default FilterComponent;
