import React from 'react';
import { array, bool, node, object, string } from 'prop-types';
import classNames from 'classnames';

import { propTypes } from '../../../util/types';
import { cardRenderSizes } from '../../../util/listingCard';

import { ListingCard, PaginationLinks } from '../../../components';

import css from './SearchResultsPanel.module.css';

const SearchResultsPanel = props => {
  const {
    pageName,
    featuredListingsState,
    airplanesMetadata,
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    isMapVariant,
    currentUser,
    onManageDisableScrolling,
    sessionIsShowFeaturedSection
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const isShowFeaturedSection = featuredListingsState
    && pagination
    && pagination.page === 1
    && !!featuredListingsState.data.length
    && sessionIsShowFeaturedSection()
  ;

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName={pageName}
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  return (
    <div className={classes}>

      {isShowFeaturedSection && (
        <div className={isMapVariant ? css.listingCardsMapVariant : css.listingCards}>
          {featuredListingsState.data.map(l => (
            <ListingCard
              airplaneMetadata={airplanesMetadata.value[l.id.uuid]}
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              priceChanged={l.attributes.publicData.price_changed}
              renderSizes={cardRenderSizes(isMapVariant)}
              setActiveListing={setActiveListing}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
            />
          ))}
        </div>
      )}

      <div className={isMapVariant ? css.listingCardsMapVariant : css.listingCards}>
        {listings.map(l => (
          <ListingCard
            airplaneMetadata={airplanesMetadata.value[l.id.uuid]}
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            priceChanged={l.attributes.publicData.price_changed}
            renderSizes={cardRenderSizes(isMapVariant)}
            setActiveListing={setActiveListing}
            currentUser={currentUser}
            onManageDisableScrolling={onManageDisableScrolling}
          />
        ))}
        {props.children}
      </div>
      {paginationLinks}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  isMapVariant: true,
};

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  isMapVariant: bool,
  pageName: string.isRequired,
};

export default SearchResultsPanel;
