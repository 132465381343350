import React from 'react';
import { func, object, string, bool, node } from 'prop-types';
import classNames from 'classnames';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import { selectStyles } from './selectStyles';

import SearchIcon from "../Topbar/SearchIcon";

import css from './FieldSelect.module.scss';
import IconClose from '../IconClose/IconClose';

const FieldAsyncSelect2Component = props => {
  const {
    onChange,
    onClear,
    loadOptions,
    name,
    id,
    className,
    selectClassName,
    innerRef,
    placeholder,
    label,
    styles,
    isMulti,
    isSearchable,
    isClearable,
    disabled,
    valid,
    value,
    showSearchIcon,
    showMultiValues,
    onSelectValue,
    defaultValue,
    defaultOptions,
    isFilter,
    hint,
  } = props;

  const selectInputRef = React.useRef();

  const customStyles = selectStyles(
    isMulti,
    showMultiValues,
    showSearchIcon,
    valid,
    styles
  );

  const classes = classNames(css.root, className);
  if (typeof window === 'undefined') {
    return null;
  }

  const handleClear = e => {
    selectInputRef.current.clearValue();
    onClear();
  };

  const handleClearDown = e => {
    e.stopPropagation();
  };

  const handleClearMobile = e => {
    e.stopPropagation();
    onClear();
  };

  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <div className={css.selectHolder}>
        {showSearchIcon && <SearchIcon className={css.searchIcon}/>}
        <AsyncSelect
          ref={selectInputRef}
          name={name}
          classNamePrefix={isFilter ? "select-filter" : "react-asyncselect"}
          className={selectClassName}
          loadOptions={loadOptions}
          placeholder={placeholder}
          styles={customStyles}
          cacheOptions
          defaultValue={defaultValue}
          value={value}
          isMulti={isMulti}
          isClearable={isClearable}
          defaultOptions={defaultOptions}
          onChange={onChange}
          components={{
            ClearIndicator: props => {
              return null
              return (
                <span
                  className={css.iconCloseWrapper}
                  onClick={handleClear}
                  onMouseDown={handleClearDown}
                  onTouchStart={handleClearMobile}
                >
                  <IconClose size='small' className={css.iconClose} />
                </span>
              );
            },
            Option: props => {
              const { children, innerProps, data } = props;

              return (
                <div className={data.className} ref={innerRef} {...innerProps}>
                  {children}
                </div>
              );
            },
            DropdownIndicator: props => {
              return (
                <components.DropdownIndicator {...props}>
                  <div className={css.caret} />
                </components.DropdownIndicator>
              );
            },
          }}
        />
        {!!value ? (
          <span
            className={css.iconCloseWrapper}
            onClick={handleClear}
            onMouseDown={handleClearDown}
            onTouchStart={handleClearMobile}
          >
            <IconClose size='small' className={css.iconClose} />
          </span>
        ) : <span className={css.iconCloseWrapper} />}
      </div>
      {hint && (
        <span className={css.hint}>
          {hint}
        </span>
      )}
    </div>
  );
}

FieldAsyncSelect2Component.defaultProps = {
  rootClassName: null,
  className: null,
  name: null,
  selectClassName: null,
  id: null,
  label: null,
  valid: true,
  defaultOptions: false,
  isClearable: true,
  hint: null,
};

FieldAsyncSelect2Component.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,
  id: string,
  label: string,
  name: string,
  input: object,
  onChange: func,
  valid: bool,
  isClearable: bool,
  hint: node,
};

export default FieldAsyncSelect2Component;
