import React from 'react';
import { bool, node, number, string } from 'prop-types';
import classNames from 'classnames';
import Switch from "react-switch";

import { FormattedMessage } from '../../../../util/reactIntl';
import mapImage from '../../../../assets/search-switcher-bg.jpg';
import css from './MainPanelHeader.module.css';

const MainPanelHeader = props => {
  const {
    rootClassName,
    isShowMap,
    className,
    children,
    resultsCount,
    searchInProgress,
    noResultsInfo,
    onShowMapChange,
    pagination,
    sortByComponent,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  let from = 0;
  let to = 0;
  if (pagination) {
    from = pagination ? ((pagination.page - 1) * pagination.perPage + 1) : 0;
    to = pagination ? ((pagination.page - 1) * pagination.perPage + pagination.perPage) : 0;
    to = to > pagination.totalItems ? pagination.totalItems : to;
  }
  const resultsText = pagination && pagination.totalItems <= pagination.perPage ? (
    <FormattedMessage
      id="AircraftSearchPage.MainPanelHeader.foundResults"
      values={{
        count: resultsCount,
      }}
    />
  ) : (
    <FormattedMessage
      id="AircraftSearchPage.MainPanelHeader.foundResultsFromTo"
      values={{
        count: resultsCount,
        from,
        to,
      }}
    />
  );

  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        <div className={css.searchResultSummary}>
          <span className={css.resultsFound}>
            {searchInProgress ? (
              <FormattedMessage id="AircraftSearchPage.MainPanelHeader.loadingResults" />
            ) : resultsText}
          </span>
        </div>

        <div className={css.sortByWrapper}>
          <span className={css.sortBy}>
            <FormattedMessage id="MainPanelHeader.sortBy" />
          </span>
          {sortByComponent}
        </div>

        <div className={css.showMapSwitcher}>
          <FormattedMessage
            id="AircraftSearchPage.MainPanelHeader.showMap"
          />
          <Switch
            onChange={onShowMapChange}
            checked={isShowMap}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow=""
            activeBoxShadow=""
            offColor="#4A4A4A"
            onColor="#2e9c2c"
            handleDiameter={20}
            width={40}
            height={24}
            uncheckedHandleIcon={
              <img
                src={mapImage}
                style={{
                  display: "block",
                  height: "20px",
                  width: "20px",
                  objectFit: "cover",
                  borderRadius: "50%"

                }}
              />
            }
            checkedHandleIcon={
              <img
                src={mapImage}
                style={{
                  display: "block",
                  height: "20px",
                  width: "20px",
                  objectFit: "cover",
                  borderRadius: "50%"
                }}
              />
            }
          />
        </div>
      </div>
      {children}
      {noResultsInfo ? noResultsInfo : null}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
};

export default MainPanelHeader;
