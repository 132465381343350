export const cardRenderSizes = isMapVariant => {
  if (isMapVariant) {
    // Panel width relative to the viewport
    const panelMediumWidth = 50;
    const panelLargeWidth = 62.5;
    return [
      '(max-width: 767px) 100vw',
      `(max-width: 1023px) ${panelMediumWidth}vw`,
      `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
      `${panelLargeWidth / 3}vw`,
    ].join(', ');
  } else {
    // Panel width relative to the viewport
    const panelMediumWidth = 50;
    const panelLargeWidth = 62.5;
    return [
      '(max-width: 549px) 100vw',
      '(max-width: 767px) 50vw',
      `(max-width: 1439px) 26vw`,
      `(max-width: 1920px) 18vw`,
      `14vw`,
    ].join(', ');
  }
};
