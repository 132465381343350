import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';

import { required } from '../../util/validators';

import CustomFieldEnum from '../../containers/EditListingPage/EditListingWizard/CustomFieldEnum';
import { Form, FieldTextInput, Button } from '../index';

import css from './SaveSearchModal.module.scss';

const options = [
  // { key: 'instant', label: 'Instant' },
  { key: 'daily', label: 'Daily' },
  { key: 'never', label: 'Never' },
];

const SaveSearchForm = (props) => {
  let formRef = null;

  React.useEffect(() => () => formRef.restart(), []);

  const inProgress = props.currentUserInProgress || props.saveSearchField.inProgress;

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          handleSubmit,
          intl,
          invalid,
          form,
          saveSearchField,
          values,
          initialValues,
        } = fieldRenderProps;

        formRef = form;

        const disabled = invalid || isEqual(values, initialValues);

        return (
          <Form
            onSubmit={handleSubmit}
          >
            <FieldTextInput
              autoFocus
              rootClassName={css.field}
              type='text'
              id='name'
              name="name"
              label={intl.formatMessage({ id: 'AddEditSearchForm.nameLabel' })}
              placeholder={intl.formatMessage({ id: 'AddEditSearchForm.namePlaceholder' })}
              validate={required(intl.formatMessage({ id: 'AddEditSearchForm.nameErrorRequired' }))}
            />

            <CustomFieldEnum
              rootClassName={css.field}
              id='freq'
              name='freq'
              options={options}
              label={intl.formatMessage({ id: 'AddEditSearchForm.freqLabel' })}
              placeholder={intl.formatMessage({ id: 'AddEditSearchForm.freqPlaceholder' })}
              defaultValue={options[0].key}
            />

            {saveSearchField.error && <div className={css.error}>{saveSearchField.error}</div>}

            <Button
              className={css.submitButton}
              type='submit'
              disabled={disabled}
              inProgress={inProgress}
            >
              {intl.formatMessage({ id: 'AddEditSearchForm.saveButton' })}
            </Button>
          </Form>
        );
      }}
    />
  );
};

export default SaveSearchForm;
