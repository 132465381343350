import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './FieldCheckbox.module.css';

const SimpleIconCheckbox = props => {
  const {
    id,
    label,
    checked,
    className,
    boxClassName,
    checkedClassName,
    textClassName,
    value,
    onClick,
  } = props;

  const handleClick = () => {
    if (onClick) {
      onClick(!checked, value);
    }
  };

  return (
    <label
      htmlFor={id}
      className={css.label}
      onClick={handleClick}
    >
        <span className={css.checkboxWrapper}>
          <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              style={{
                fill: checked ? 'rgb(227, 41, 34)' : 'none',
              }}
              className={boxClassName || css.box}
              fill="#E32922"
              stroke="#5D4F54"
              d="M0.5 0.5H19.5V19.5H0.5z"
            />
            <path
              style={{
                display: checked ? 'block' : 'none',
                fill: checked ? 'rgb(255, 255, 255)' : 'none',
              }}
              className={checkedClassName || css.checked}
              fill="#fff"
              d="M17.083 6.472L15.5 5l-7.743 7.5-3.257-3-1.582 1.542 4.839 4.844 9.326-9.414z"
            />
          </svg>
        </span>
      <span className={classNames(css.text, textClassName || css.textRoot)}>{label}</span>
    </label>
  );
};

SimpleIconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null };

SimpleIconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };

export default SimpleIconCheckbox;
