import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './SearchFiltersPrimary.module.css';

const SearchFiltersPrimaryComponent = props => {
  const {
    showMoreFilters,
    rootClassName,
    className,
    children,
    isSecondaryFiltersOpen,
    toggleSecondaryFiltersOpen,
    selectedSecondaryFiltersCount,
    hasSearchParams,
    resetAll,
    onSaveSearchClick,
    visibleSearchButton
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const toggleSecondaryFiltersOpenButtonClasses =
    isSecondaryFiltersOpen || selectedSecondaryFiltersCount > 0
      ? css.searchFiltersPanelOpen
      : css.searchFiltersPanelClosed;
  const toggleSecondaryFiltersOpenButton = (showMoreFilters && toggleSecondaryFiltersOpen) ? (
    <button
      className={classNames(css.searchFiltersPanelOpen, {[css.searchFiltersPanelSelected] : selectedSecondaryFiltersCount > 0})}
      onClick={() => {
        toggleSecondaryFiltersOpen(!isSecondaryFiltersOpen);
      }}
    >
      <FormattedMessage
        id="SearchFiltersPrimary.moreFiltersButton"
        values={{ count: selectedSecondaryFiltersCount }}
      />
    </button>
  ) : null;

  return (
    <div className={classes}>
      <div className={css.filters}>
        {children}
        {toggleSecondaryFiltersOpenButton}
        {hasSearchParams && (
          <>
            <button
              className={css.resetAllFiltersButton}
              onClick={e => resetAll(e)}
            >
              <FormattedMessage id={'SearchPage.resetAllFilters'} />
            </button>
            {visibleSearchButton && <button
              className={css.resetAllFiltersButton}
              onClick={onSaveSearchClick}
            >
              <FormattedMessage id={'SearchPage.saveSearch'} />
            </button>}
          </>
        )}
      </div>
    </div>
  );
};

SearchFiltersPrimaryComponent.defaultProps = {
  showMoreFilters: true,
  rootClassName: null,
  className: null,
  isSecondaryFiltersOpen: false,
  toggleSecondaryFiltersOpen: null,
  selectedSecondaryFiltersCount: 0,
};

SearchFiltersPrimaryComponent.propTypes = {
  showMoreFilters: bool,
  rootClassName: string,
  className: string,
  isSecondaryFiltersOpen: bool,
  toggleSecondaryFiltersOpen: func,
  selectedSecondaryFiltersCount: number,
};

const SearchFiltersPrimary = SearchFiltersPrimaryComponent;

export default SearchFiltersPrimary;
