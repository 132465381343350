import React from 'react';

import {
  LISTING_TYPE__REAL_ESTATE,
} from '../../../util/editListingHelpers';

import css from './SearchMapPriceLabel.module.css';

const Tooltip = (props) => {
  const {
    listingType,
  } = props;

  if (listingType === LISTING_TYPE__REAL_ESTATE) {
    return (
      <svg
        className={css.tooltip}
        width="52"
        height="40"
        viewBox="0 0 52 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1_48595)">
          <path d="M14.6445 19.3272L26.103 12.757L37.5623 19.3272H37.5671V31.9992H14.6445V19.3272Z" fill="#242424"/>
          <path d="M26.0029 9.01252L25.0781 10.6031L40.0786 19.2033L41.0034 17.6128L26.0029 9.01252Z" fill="#242424"/>
          <path d="M11.9247 19.1908L26.9247 10.5901L26.0008 9L11 17.6007" fill="#242424"/>
          <path d="M28.6662 22.8018H23.5508V32H28.6662V22.8018Z" fill="white"/>
          <path d="M36.8533 9.64705H33.707V15.279H36.8533V9.64705Z" fill="#242424"/>
        </g>
      </svg>
    );
  }

  return (
    <svg
      className={css.tooltip}
      width="54"
      height="61"
      viewBox="0 0 54 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_546_1578)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 3C7.79086 3 6 4.79086 6 7V41C6 43.2091 7.79086 45 10 45H21.9289L28 51.0711L34.0711 45H44C46.2091 45 48 43.2091 48 41V7C48 4.79086 46.2091 3 44 3H10Z"
          fill="white"
        />
      </g>
      <path
        d="M39.6967 12.0279C40.6063 12.9375 40.6063 14.4021 39.6967 15.2963L33.6996 21.2933L36.968 35.4613L34.7942 37.6504L28.8126 26.1958L22.8001 32.2083L23.3551 36.0163L21.7055 37.6504L18.9921 32.7479L14.0742 30.0192L15.7084 28.3542L19.5626 28.9246L25.5288 22.9583L14.0742 16.9304L16.2634 14.7567L30.4313 18.025L36.4284 12.0279C37.2917 11.1338 38.8334 11.1338 39.6967 12.0279Z"
        fill="#E32922"
      />
      <defs>
        <filter
          id="filter0_d_546_1578"
          x="0"
          y="0"
          width="54"
          height="60.071"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="3"/>
          <feGaussianBlur stdDeviation="3"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_546_1578"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_546_1578" result="shape"/>
        </filter>
      </defs>
    </svg>
  );
};

export default Tooltip;
