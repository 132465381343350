import React from 'react';
import isEqual from 'lodash/isEqual';

import {
  getMakersPromise,
  getModelFamilyPromise,
  getModelsPromise,
} from '../../../../ducks/AirplanesData.duck';
import {
  FILTER_KEY_MAKER,
  FILTER_KEY_MODEL,
  FILTER_KEY_MODEL_FAMILY,
} from '../../SearchPage.constants';

import FilterPopup from '../FilterPopup/FilterPopup';
import FilterPlain from '../FilterPlain/FilterPlain';
import AsyncSelect2 from '../../../../components/FieldSelect/AsyncSelect2';
import Select2 from '../../../../components/FieldSelect/Select2';

import css from './MakeModelFilter.module.css';

const MakeModelFilter = (props) => {
  const {
    label,
    intl,
    showAsPopup,
    searchValues,
    onSubmit,
    onChangeMobileFilters,
  } = props;

  const defaultMake = searchValues[FILTER_KEY_MAKER];
  const defaultModelFamily = searchValues[FILTER_KEY_MODEL_FAMILY];
  const defaultModel = searchValues[FILTER_KEY_MODEL];

  const [make, setMake] = React.useState(null);
  const [modelFamily, setModelFamily] = React.useState(null);
  const [model, setModel] = React.useState(null);
  const [modelFamilyOptions, setModelFamilyOptions] = React.useState([]);
  const [modelOptions, setModelOptions] = React.useState([]);

  React.useEffect(() => {
    setMake(defaultMake);
    setModelFamily(defaultModelFamily);
    setModel(defaultModel);
    if (defaultMake) {
      fetchModelFamilyOptions(defaultMake.value);
      fetchModelOptions(defaultMake.value, defaultModelFamily && defaultModelFamily.value);
    }
  }, [defaultMake, defaultModelFamily, defaultModel]);

  const hasInitialValues = defaultMake || defaultModel || defaultModelFamily;

  const promiseMakerOptions = inputValue => {
    return getMakersPromise(inputValue);
  };

  const fetchModelFamilyOptions = (makerId, modelId) => {
    getModelFamilyPromise(null, makerId, modelId)
      .then(resp => setModelFamilyOptions(resp))
      .catch(() => {})
    ;
  };

  const fetchModelOptions = (makerId, modelFamily) => {
    getModelsPromise(null, makerId, modelFamily)
      .then(resp => setModelOptions(resp))
      .catch(() => {})
    ;
  }

  const handleSubmit = () => {
    onSubmit({
      [FILTER_KEY_MAKER]: make,
      [FILTER_KEY_MODEL_FAMILY]: modelFamily,
      [FILTER_KEY_MODEL]: model,
    });
  };

  const handleCancel = () => {
    if (!isEqual(defaultMake, make)) {
      setMake(defaultMake);
    }
    if (!isEqual(defaultModelFamily)) {
      setModelFamily(defaultModelFamily);
    }
    if (!isEqual(defaultModel, model)) {
      setModel(defaultModel);
    }
  };

  const handleClear = () => {
    setMake(null);
    setModel(null);
    setModelFamily(null);
    if (showAsPopup) {
      onSubmit({
        [FILTER_KEY_MAKER]: null,
        [FILTER_KEY_MODEL_FAMILY]: null,
        [FILTER_KEY_MODEL]: null,
      });
    } else {
      onChangeMobileFilters({
        [FILTER_KEY_MAKER]: null,
        [FILTER_KEY_MODEL_FAMILY]: null,
        [FILTER_KEY_MODEL]: null,
      });
    }
  };

  const handleMakeChange = value => {
    setMake(value);
    setModelFamily(null);
    setModel(null);
    if (value) {
      fetchModelFamilyOptions(value.value);
      fetchModelOptions(value.value);
    }
    if (!showAsPopup) {
      onChangeMobileFilters({
        [FILTER_KEY_MAKER]: value && value.value,
        [FILTER_KEY_MODEL_FAMILY]: null,
        [FILTER_KEY_MODEL]: null,
      });
      // onSubmit({
      //   [FILTER_KEY_MAKER]: value,
      //   [FILTER_KEY_MODEL_FAMILY]: null,
      //   [FILTER_KEY_MODEL]: null,
      // });
    }
  };

  const handleModelFamilyChange = value => {
    setModelFamily(value);
    setModel(null);
    fetchModelOptions(make.value, value && value.value);

    if (!showAsPopup) {
      onChangeMobileFilters({
        [FILTER_KEY_MAKER]: make && make.value,
        [FILTER_KEY_MODEL_FAMILY]: value && value.value,
        [FILTER_KEY_MODEL]: null,
      });
      // onSubmit({
      //   [FILTER_KEY_MAKER]: make,
      //   [FILTER_KEY_MODEL_FAMILY]: value,
      //   [FILTER_KEY_MODEL]: model,
      // });
    }
  };

  const handleModelChange = value => {
    setModel(value);
    // fetchModelFamilyOptions(make.value, value && value.value);
    if (!showAsPopup) {
      onChangeMobileFilters({
        [FILTER_KEY_MAKER]: make && make.value,
        [FILTER_KEY_MODEL_FAMILY]: modelFamily && modelFamily.value,
        [FILTER_KEY_MODEL]: value && value.value,
      });

      // onSubmit({
      //   [FILTER_KEY_MAKER]: make,
      //   [FILTER_KEY_MODEL_FAMILY]: modelFamily,
      //   [FILTER_KEY_MODEL]: value,
      // });
    }
  };

  const handleMakeClear = () => {
    setMake(null);
    setModelFamily(null);
    setModel(null);

    if (!showAsPopup) {
      onChangeMobileFilters({
        [FILTER_KEY_MAKER]: null,
        [FILTER_KEY_MODEL_FAMILY]: null,
        [FILTER_KEY_MODEL]: null,
      });
    }
  };

  const handleModelFamilyClear = () => {
    setModelFamily(null);
    setModel(null);
    fetchModelFamilyOptions(make && make.value);
    fetchModelOptions(make && make.value);

    if (!showAsPopup) {
      onChangeMobileFilters({
        [FILTER_KEY_MAKER]: make && make.value,
        [FILTER_KEY_MODEL_FAMILY]: null,
        [FILTER_KEY_MODEL]: null,
      });
    }
  };

  const handleModelClear = () => {
    setModel(null);
    fetchModelFamilyOptions(make && make.value);
    fetchModelOptions(make && make.value, modelFamily && modelFamily.value);

    if (!showAsPopup) {
      onChangeMobileFilters({
        [FILTER_KEY_MAKER]: make && make.value,
        [FILTER_KEY_MODEL_FAMILY]: modelFamily && modelFamily.value,
        [FILTER_KEY_MODEL]: null,
      });
    }
  };

  const makeLabel = intl.formatMessage({
    id: 'AircraftSearchPage.primaryFilters.makeModelFilter.makeLabel',
  });
  const modelFamilyLabel = intl.formatMessage({
    id: 'AircraftSearchPage.primaryFilters.makeModelFilter.modelFamilyLabel',
  });
  const modelLabel = intl.formatMessage({
    id: 'AircraftSearchPage.primaryFilters.makeModelFilter.modelLabel',
  });
  const makePlaceholder = intl.formatMessage({
    id: 'AircraftSearchPage.primaryFilters.makeModelFilter.makePlaceholder',
  });
  const modelFamilyPlaceholder = intl.formatMessage({
    id: 'AircraftSearchPage.primaryFilters.makeModelFilter.modelFamilyPlaceholder',
  });
  const modelPlaceholder = intl.formatMessage({
    id: 'AircraftSearchPage.primaryFilters.makeModelFilter.modelPlaceholder',
  });
  const tip = intl.formatMessage({
    id: 'AircraftSearchPage.primaryFilters.makeModelFilter.makeModelTip',
  });

  const content = (
    <>
      <AsyncSelect2
        label={makeLabel}
        value={make}
        placeholder={makePlaceholder}
        hint={tip}
        loadOptions={promiseMakerOptions}
        onChange={handleMakeChange}
        onClear={handleMakeClear}
      />
      {make && (
        <>
          <Select2
            label={modelFamilyLabel}
            value={modelFamily}
            placeholder={modelFamilyPlaceholder}
            options={modelFamilyOptions}
            isSearchable
            onChange={handleModelFamilyChange}
            onClear={handleModelFamilyClear}
          />

          <Select2
            label={modelLabel}
            value={model}
            placeholder={modelPlaceholder}
            options={modelOptions}
            isSearchable
            onChange={handleModelChange}
            onClear={handleModelClear}
          />
        </>
      )}
    </>
  );

  if (showAsPopup) {
    return (
      <FilterPopup
        id={'make_model_filter_popup'}
        intl={intl}
        label={label}
        showAsPopup={showAsPopup}
        isSelected={!!hasInitialValues}
        onClear={handleClear}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      >
        {content}
      </FilterPopup>
    );
  } else {
    return (
      <FilterPlain
        id={'make_model_filter_plain'}
        label={label}
        isSelected={!!hasInitialValues}
        onClear={handleClear}
        onSubmit={() => {}}
      >
        {content}
      </FilterPlain>
    )
  }
};

export default MakeModelFilter;
