import React from 'react';
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { Field } from 'react-final-form';
import classNames from 'classnames';
import numeral from 'numeral';

import { ValidationError } from '../index';

import css from './FieldMaskedCurrencyInput.scss';

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 15,
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const MaskedCurrencyInputAdapter = props => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...props,
  });

  const {
    input,
  } = props;

  return <MaskedInput mask={currencyMask} {...props} {...input} />;
};

export const FieldMaskedCurrencyInputAdapter = props => {
  const {
    id,
    rootClassName,
    className,
    label,
    meta,
    input,
    customErrorText,
    inputRootClass,
    ...rest
  } = props;

  const { valid, invalid, touched, error } = meta;
  const errorText = customErrorText || error;
  const hasError = !!customErrorText || !!(touched && invalid && error);
  const fieldMeta = { touched: hasError, error: errorText };
  const classes = classNames(rootClassName, className);
  const inputClasses =
    inputRootClass ||
    classNames(css.input, {
      [css.inputSuccess]: valid,
      [css.inputError]: hasError,
    });
  const inputProps = {
    className: inputClasses,
    id,
    ...input,
    ...rest,
  };

  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <MaskedCurrencyInputAdapter {...inputProps} />
      <ValidationError fieldMeta={fieldMeta} />
    </div>
  )
}

const FieldMaskedCurrencyInput = props => {
  return (
    <Field
      {...props}
      parse={value => value &&  numeral(value).value()}
      component={FieldMaskedCurrencyInputAdapter}
    />
  );
};

FieldMaskedCurrencyInput.defaultProps = {
  inputMode: 'numeric',
  maskOptions: {},
}

FieldMaskedCurrencyInput.propTypes = {
  inputMode: PropTypes.string,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.bool,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.bool,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.bool,
    allowNegative: PropTypes.bool,
    allowLeadingZeroes: PropTypes.bool,
    integerLimit: PropTypes.number,
  }),
}

export default FieldMaskedCurrencyInput;
